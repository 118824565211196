export default class Address {
  public suburb: string = ''
  public street: string = ''
  public zipCode: string = ''
  public no_ext: string = ''
  public no_int?: string

  constructor(address: Address) {
    Object.assign(this, address)
  }

  toString() {
    const addressData = []
    let firstPart = ''

    if (this.street) {
      firstPart += this.street
    }

    if (this.no_ext) {
      if (this.no_int) {
        firstPart += ` No. ${this.no_ext} - ${this.no_int}`
      } else {
        firstPart += ` No. ${this.no_ext}`
      }
    }

    addressData.push(firstPart)

    if (this.suburb) {
      addressData.push(`${this.suburb}`)
    }

    if (this.zipCode) {
      addressData.push(`C.P. ${this.zipCode}`)
    }

    return addressData.join(', ')
  }
}
