import React, { Component } from 'react'
import Formsy from 'formsy-react'
import { AButton, AFormatLabel } from 'shared/components/common'

import { InputFormsy } from 'shared/components/formsy'
import { Row, Col } from 'reactstrap'
import { SearchServiceProps, SearchServiceState } from './types'
export class SearchService extends Component<SearchServiceProps, SearchServiceState> {
  form: any
  state: SearchServiceState = {}
  onSubmit = (model: any) => {
    if (this.props.onConfirm) {
      this.props.onConfirm(model.code)
    }
    this.form.reset()
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }
  render() {
    const { canSubmit } = this.state
    const { disabled } = this.props
    return (
      <Formsy
        ref={(node: any) => {
          this.form = node
        }}
        onValidSubmit={this.onSubmit}
        onValid={this.onValidForm}
        onInvalid={this.onInvalidForm}
      >
        <Row>
          <Col sm={4}>
            <InputFormsy
              name="code"
              label="Código de servicio"
              inputProps={{ disabled, placeholder: 'Escribe el código único de tu servicio', uppercase: true }}
              required
            />
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <AFormatLabel className="invisible">KRIBLET</AFormatLabel>
            <AButton variant="pink" type="submit" disabled={!canSubmit || disabled}>
              Buscar
            </AButton>
          </Col>
        </Row>
      </Formsy>
    )
  }
}

export default SearchService
