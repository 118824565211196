import { ApiService } from 'core/services'
import { Config } from 'core'

class CaruselService {
  public static instance: CaruselService

  constructor() {
    if (CaruselService.instance) {
      return CaruselService.instance
    }
    CaruselService.instance = this
  }

  getImg() {
    return ApiService.get<any[]>('site/web', { url: Config.apiUrl })
  }
}

const instance = new CaruselService()

Object.freeze(instance)

export const Carusel = instance
