import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { ServiceInfoProps, ServiceInfoState } from './types'
import { currency } from 'core/utils'

export class ServiceInfo extends Component<ServiceInfoProps, ServiceInfoState> {
  render() {
    const { amount = 0, iva = 0, discount = 0, total_amount = 0 } = this.props
    return (
      <Container>
        <Row>
          <Col sm={3}>
            <div className="detalle">
              <h5 className="big-blue">Importe</h5>
              <p className="m-0">{currency.format(amount)}</p>
            </div>
          </Col>
          <Col sm={3}>
            <div className="detalle">
              <h5 className="big-blue">IVA</h5>
              <p className="m-0">{currency.format(iva)}</p>
            </div>
          </Col>
          <Col sm={3}>
            <div className="detalle">
              <h5 className="big-blue">Descuento</h5>
              <p className="m-0">{currency.format(discount)}</p>
            </div>
          </Col>
          <Col sm={3}>
            <div className="detalle">
              <h5 className="big-blue">Total</h5>
              <p className="m-0">{currency.format(total_amount)}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ServiceInfo
