import React, { Component } from 'react'
import Formsy, { addValidationRule } from 'formsy-react'

import { BillingInfoSearchProps, BillingInfoSearchState } from './types'

import { validRFC } from 'core/utils'
import { Row, Col } from 'reactstrap'
import { InputFormsy } from 'shared/components/formsy'
import { AFormatLabel, AButton } from 'shared/components/common'

addValidationRule('isRfc', (_values: any, value: string) => {
  return value && validRFC(value.trim().toUpperCase())
})

export class BillingInfoSearch extends Component<BillingInfoSearchProps, BillingInfoSearchState> {
  form: any
  state: BillingInfoSearchState = {}
  onSubmit = (model: any) => {
    if (this.props.onConfirm) {
      this.props.onConfirm(model)
    }
    this.form.reset()
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }
  render() {
    const { canSubmit } = this.state
    const { disabled } = this.props
    return (
      <Formsy
        ref={(node: any) => {
          this.form = node
        }}
        onValidSubmit={this.onSubmit}
        onValid={this.onValidForm}
        onInvalid={this.onInvalidForm}
      >
        <Row>
          <Col sm={4}>
            <InputFormsy
              name="rfc"
              label="RFC:"
              inputProps={{ disabled, placeholder: 'Escribe tu rfc', maxLength: 13, uppercase: true }}
              validations={{ isRfc: true }}
              validationErrors={{ isRfc: 'El texto introducido no es un rfc válido' }}
              required
            />
          </Col>
          <Col sm={4}>
            <InputFormsy
              name="zipCode"
              label="Código Postal:"
              inputProps={{ disabled, placeholder: 'Escribe tu código postal', maxLength: 5 }}
              validations={{
                isNumeric: true,
                minLength: 5
              }}
              validationErrors={{
                isNumeric: 'El número introducido no es un código postal válido',
                minLength: 'El número introducido no tiene una longitud válida'
              }}
              required
            />
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <AFormatLabel className="invisible">KRIBLET</AFormatLabel>
            <AButton variant="med" type="submit" disabled={!canSubmit || disabled}>
              Buscar
            </AButton>
          </Col>
        </Row>
      </Formsy>
    )
  }
}

export default BillingInfoSearch
