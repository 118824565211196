export function getBillingEmailString(siteUrl: string, code: string, clientName: string) {
  return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>allAbordo</title>
    <style type="text/css" data-hse-inline-css="true">
		body {
			font-family: Helvetica, Arial, serif;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin: 0 !important;
			width: 100% !important;
			-webkit-text-size-adjust: 100% !important;
			-ms-text-size-adjust: 100% !important;
			-webkit-font-smoothing: antialiased !important;
		}

		.tableContent img {
			border: 0 !important;
			display: block !important;
			outline: none !important;
		}

		a {
			color: #382F2E;
		}

		p,
		h1 {
			color: #382F2E;
			margin: 0;
		}

		p,
		ul,
		li {
			text-align: left;
			color: #999999;
			font-size: 14px;
			font-weight: normal;
			line-height: 19px;
		}

		a.link1 {
			color: #382F2E;
		}

		a.link2 {
			font-size: 16px;
			text-decoration: none;
			color: #ffffff;
		}

		h2 {
			text-align: left;
			color: #222222;
			font-size: 19px;
			font-weight: normal;
		}

		div,
		p,
		ul,
		h1,
		h3 {
			margin: 0;
		}

		h3 {
			font-size: 18px;
			font-weight: normal;
		}

		.bgBody {
			background: #ffffff;
		}

		.bgItem {
			background: #ffffff;
		}

		.pt-10 {
			padding-top: 20px;
		}

		.pt-20 {
			padding-top: 20px;
		}

		.pt-40 {
			padding-top: 40px;
		}

		.text-center {
			text-align: center;
		}

		.text-right {
			text-align: right;
		}

		.text-justify {
			text-align: justify;
		}

		.text-uppercase {
			text-transform: uppercase;
		}

		.text-semibold {
			font-weight: 600;
		}

		.list-without-style {
			list-style: none;
		}

		.pd-l-0{
			padding-left: 0px;
		}

		@media only screen and (max-width:480px) {

			table[class="MainContainer"],
			td[class="cell"] {
				border: solid 1px;
				width: 100% !important;
				height: auto !important;
			}

			td[class="specbundle"] {
				width: 100% !important;
				float: left !important;
				font-size: 13px !important;
				line-height: 17px !important;
				display: block !important;
				padding-bottom: 15px !important;
			}

			td[class="spechide"] {
				display: none !important;
			}

			img[class="banner"] {
				width: 100% !important;
				height: auto !important;
			}

			td[class="left_pad"] {
				padding-left: 15px !important;
				padding-right: 15px !important;
			}

		}

		@media only screen and (max-width:540px) {

			table[class="MainContainer"],
			td[class="cell"] {
				width: 100% !important;
				height: auto !important;
			}

			td[class="specbundle"] {
				width: 100% !important;
				float: left !important;
				font-size: 13px !important;
				line-height: 17px !important;
				display: block !important;
				padding-bottom: 15px !important;
			}

			td[class="spechide"] {
				display: none !important;
			}

			img[class="banner"] {
				width: 100% !important;
				height: auto !important;
			}

			.font {
				font-size: 18px !important;
				line-height: 22px !important;

			}

			.font1 {
				font-size: 18px !important;
				line-height: 22px !important;

			}
			.text-center {
				text-align: center;
			}
		}
	</style>
    <script type="colorScheme" class="swatch active">
		{ "name":"Default", "bgBody":"ffffff", "link":"382F2E", "color":"999999", "bgItem":"ffffff", "title":"222222" }
	</script>
</head>

<body paddingwidth="0" paddingheight="0" style="padding-top: 10; padding-bottom: 10; margin-top: 10; margin-bottom: 10; background-repeat: repeat; width: 100% !important; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; -webkit-font-smoothing: antialiased;"
      offset="0" toppadding="0" leftpadding="0">
<table bgcolor="#FAFAFA" width="100%" border="0" cellspacing="0" cellpadding="0" class="tableContent" align="center">
    <tbody>
    <tr>
        <td height="20"></td>
    </tr>
    <tr>
        <td>
            <table width="600" border="0" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff" class="MainContainer">
                <tbody>
                <tr>
                    <td>
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                            <tr>
                                <td valign="top" width="40">&nbsp;</td>
                                <td>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tbody>
                                        <!-- =============================== Header ====================================== -->
                                        <tr>
                                            <td height='75' class="spechide" valign='top' align='center'>
                                                <img src="https://image.ibb.co/dL7A2U/allabordo_fondo.png" width='310' height='auto' alt='' data-default="placeholder" data-max-width="560">
                                            </td>
                                        </tr>
                                        <!-- =============================== Body ====================================== -->
                                        <tr>
                                            <td class='movableContentContainer ' valign='top'>
                                                <div class="movableContent" style="border: 0px; padding-top: 0px; position: relative;">
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                        <tr>
                                                            <td height="35"></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="movableContent" style="border: 0px; padding-top: 0px; position: relative;">
                                                    <!-- AQUI TEMPLATE -->
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
                                                        <tr>
                                                            <td>
                                                                <h2 class="text-center">Factura del servicio: ${code}.</h2>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td height='15'> </td>
                                                        </tr>
                                                        <tr>
                                                            <td align='left'>
                                                                <div class="contentEditableContainer contentTextEditable">
                                                                    <div class="contentEditable" align='center'>
                                                                        <p class="text-justify">
                                                                            Hola ${clientName} gracias por elegir AllAbordo. Para ver tu factura ingresa en el siguiente link:
                                                                            <a href="${siteUrl}/imprimirfactura/?code=${code}">Imprimir factura</a>
                                                                            <br>
                                                                            <br>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- =============================== footer ====================================== -->
                                        <tr>
                                            <td height='25'></td>
                                        </tr>
                                        <tr>
                                            <td style='border-bottom:1px solid #dddddde1; margin-top: 100px;'></td>
                                        </tr>
                                        <tr>
                                            <td height='25'></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="movableContent" style="border: 0px; padding-top: 0px; position: relative;">
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                    <tbody>
                                                                    <tr>
                                                                        <td valign="top" class="specbundle">
                                                                            <div class="contentEditableContainer contentTextEditable">
                                                                                <div class="contentEditable" valign='center'>
                                                                                    <p style='text-align:left;color:#CCCCCC;font-size:12px;font-weight:normal;line-height:20px;'>
                                                                                        <span style='font-weight:bold;'>2018 - Derechos reservados - allABORDO</span>
                                                                                        <a target='_blank' href="www.allabordo.com"> www.allabordo.com</a>
                                                                                        <br>

                                                                                        <span style='font-weight:bold;'>
																														Si tienes alguna duda o sugerencia puedes comunicarte directamente con nosotros a <a target='_blank' href="[FORWARD]">contacto@ptxpaqueteria.com</a><br>
																													</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td valign="top" width="30" class="specbundle">&nbsp;</td>
                                                                        <td valign="top" class="specbundle">
                                                                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td valign='top' width='52'>
                                                                                        <div class="contentEditableContainer contentFacebookEditable">
                                                                                            <div class="contentEditable">
                                                                                                <a target='_blank' href="#">
                                                                                                    <img src="https://image.ibb.co/b3ATbp/facebook.png" width='52' height='53' alt='facebook icon' data-default="placeholder" data-max-width="52" data-customIcon="true">
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td valign="top" width="16">&nbsp;</td>
                                                                                    <td valign='top' width='52'>
                                                                                        <div class="contentEditableContainer contentTwitterEditable">
                                                                                            <div class="contentEditable">
                                                                                                <a target='_blank' href="#">
                                                                                                    <img src="https://image.ibb.co/eu1vNU/twitter.png"  width='52' height='53' alt='twitter icon' data-default="placeholder" data-max-width="52" data-customIcon="true">
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td height='88'></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td valign="top" width="40">&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
    <tr>
        <td height="20"></td>
    </tr>
    </tbody>
</table>


</body>

</html>

`
}
