import React, { Component, Fragment } from 'react'
import { AButton } from 'shared/components/common'
import { EmailCaptureProps, EmailCaptureState } from './types'
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap'
import Formsy, { addValidationRule } from 'formsy-react'
import Container from 'reactstrap/lib/Container'
import { InputFormsy, SelectFormsy } from 'shared/components/formsy'
import { TaxRegimen, UsoCFDI } from 'modules/invoice/services/InvoiceService/types'
import InvoiceService from 'modules/invoice/services/InvoiceService/InvoiceService'

addValidationRule('isEnterpriseName', (_values: any, value: string) => {
  return value && /[^\|]/g.test(value)
})

export class EmailCapture extends Component<EmailCaptureProps, EmailCaptureState> {
  form: any
  state: EmailCaptureState = {
    taxRegimens: [],
    usosCFDI: []
  }
  availableTaxRegimens: TaxRegimen[] = []
  availableUsosCFDI: UsoCFDI[] = []

  constructor(props: EmailCaptureProps) {
    super(props)
    this.fetchTaxRegimens()
    this.fetchUsosCFDI()
  }

  fetchTaxRegimens = () => {
    InvoiceService.getTaxRegimens()
      .then(data => {
        this.setState({ taxRegimens: data })
      })
      .catch(err => {
        console.error('Error fetching tax regimens:', err)
      })
  }

  fetchUsosCFDI = () => {
    InvoiceService.getUsosCFDI()
      .then(data => {
        this.setState({ usosCFDI: data })
      })
      .catch(err => {
        console.error('Error fetching usos cfdi:', err)
      })
  }

  toggle = () => {
    const { reservationData, billingInfo } = this.props
    const { modal } = this.state

    if (!modal) {
      if (reservationData?.purchase_cbi && billingInfo.rfc !== reservationData.purchase_cbi.rfc) {
        this.setState({
          errorModal: true,
          errorMessage: `El RFC elegido no coincide con el proporcionado en la documentación: <b>${reservationData?.purchase_cbi?.rfc}</b><br>Verifica que estés eligiendo los datos correctos para facturar`
        })
        return
      }
    }
    this.setState({ modal: !modal })
  }

  getForm = (f: any) => {
    if (f) {
      this.form = f
      const { name = '', zip_code = '', default: email = '', legal_person = '', billingInfo, reservationData } = this.props
      const regimenFiscalId = billingInfo?.c_RegimenFiscal_id || ''
      const usoCFDIId = billingInfo?.c_UsoCFDI_id || ''

      this.availableTaxRegimens = legal_person
        ? this.state.taxRegimens.filter((t: any) => t[`apply_${[legal_person === 'fisico' ? 'fisica' : 'moral']}`])
        : this.state.taxRegimens
      this.availableUsosCFDI = legal_person
        ? this.state.usosCFDI.filter((t: any) => t[`apply_${[legal_person === 'fisico' ? 'fisica' : 'moral']}`])
        : this.state.usosCFDI

      const selectedRegimenFiscal = this.availableTaxRegimens.find(regimen => regimen.id === regimenFiscalId)
      const selectedUsoCFDI = this.availableUsosCFDI.find(uso => uso.id === usoCFDIId)

      this.form.reset({
        email,
        tax_regimen: selectedRegimenFiscal ? selectedRegimenFiscal.c_RegimenFiscal : '',
        uso_cfdi: selectedUsoCFDI ? selectedUsoCFDI.c_UsoCFDI : '',
        payment_condition: reservationData?.payment_condition || '',
        zip_code: zip_code.toString(),
        name: this.removeAccents(name)
      })
    }
  }

  onSubmit = (model: any) => {
    const { onConfirm } = this.props
    if (onConfirm) {
      const name = this.removeAccents(model.name)
      onConfirm(name, model.zip_code, model.tax_regimen, model.email, model.uso_cfdi, model.payment_condition)
    }
    this.toggle()
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }

  removeAccents(str: string) {
    return str
      .replace(/[áäàâ]/gi, 'a')
      .replace(/[éëèê]/gi, 'e')
      .replace(/[íïìî]/gi, 'i')
      .replace(/[óöòô]/gi, 'o')
      .replace(/[úüùû]/gi, 'u')
      .trim()
      .toUpperCase()
  }

  toggleErrorModal = () => {
    this.setState(state => ({ errorModal: !state.errorModal }))
  }

  render() {
    const { canSubmit, errorModal, errorMessage } = this.state
    return (
      <Fragment>
        <AButton variant="pink" onClick={this.toggle} className="font-weight-bold text-bold">
          Generar factura
        </AButton>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" centered backdrop="static">
          <Formsy ref={this.getForm} onValidSubmit={this.onSubmit} onValid={this.onValidForm} onInvalid={this.onInvalidForm}>
            <ModalHeader toggle={this.toggle}>
              <label className="bluep h6">
                <i className="fas fa-address-book" style={{ paddingRight: 10 }} /> Datos de facturación
              </label>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col sm={12} className="mb-2">
                    <InputFormsy
                      name="name"
                      className="mb-0"
                      label="Razón social:"
                      inputProps={{ placeholder: 'Razón social sin régimen societario', maxLength: 250, uppercase: true }}
                      validations={{ isEnterpriseName: true }}
                      validationErrors={{ isEnterpriseName: 'El texto introducido no es una razón social válida' }}
                      required
                    />
                    <small className="lil-blue">La razón social NO debe incluir el régimen societario (ej. S.A de C.V)</small>
                  </Col>
                  <Col sm={12} className="mb-2">
                    <InputFormsy
                      name="zip_code"
                      label="Código Postal:"
                      inputProps={{ placeholder: 'Código postal ', maxLength: 5 }}
                      validations={{
                        matchRegexp: /^\d{5}$/,
                        minLength: 5,
                        maxLength: 5
                      }}
                      validationErrors={{
                        matchRegexp: 'El código postal debe contener 5 dígitos',
                        minLength: 'El código postal debe contener 5 dígitos',
                        maxLength: 'El código postal debe contener 5 dígitos'
                      }}
                      required
                    />
                  </Col>

                  <Col sm={12} className="mb-2">
                    <SelectFormsy name="tax_regimen" label="Régimen fiscal" value="" required>
                      <option value="">Selecciona el régimen fiscal</option>
                      {this.availableTaxRegimens.map((regimen: TaxRegimen) => (
                        <option key={regimen.c_RegimenFiscal} value={regimen.c_RegimenFiscal}>
                          {regimen.c_RegimenFiscal} - {regimen.description}
                        </option>
                      ))}
                    </SelectFormsy>
                  </Col>
                  <Col sm={12} className="mb-2">
                    <SelectFormsy name="uso_cfdi" label="Uso de CFDI" value="" required>
                      <option value="">Selecciona uso de CFDI</option>
                      {this.availableUsosCFDI.map((uso: UsoCFDI) => (
                        <option key={uso.c_UsoCFDI} value={uso.c_UsoCFDI}>
                          {uso.c_UsoCFDI} - {uso.description}
                        </option>
                      ))}
                    </SelectFormsy>
                  </Col>
                  <Col sm={12} className="mb-2">
                    <InputFormsy
                      name="email"
                      label="Correo electrónico:"
                      inputProps={{ placeholder: 'Escribe tu correo electrónico' }}
                      validations={{ isEmail: true }}
                      validationErrors={{ isEmail: 'El texto introducido no es un correo válido' }}
                      required
                    />
                  </Col>
                  <Col sm={12} className="mb-2 hidden-input">
                    <InputFormsy
                      name="payment_condition"
                      label="Condición de Pago:"
                      inputProps={{ placeholder: 'Condición de Pago', readOnly: true }}
                      validations={{}}
                      required
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <AButton type="submit" variant="pink" disabled={!canSubmit}>
                Generar
              </AButton>
              <AButton type="button" variant="gray" onClick={this.toggle}>
                Cancelar
              </AButton>
            </ModalFooter>
          </Formsy>
        </Modal>

        {/* Modal de error */}
        <Modal isOpen={errorModal} toggle={this.toggleErrorModal}>
          <ModalHeader toggle={this.toggleErrorModal}>Aviso</ModalHeader>
          <ModalBody>
            <p className="big-blue-mon bold" dangerouslySetInnerHTML={{ __html: errorMessage || '' }} />
          </ModalBody>
          <ModalFooter>
            <AButton variant="gray" onClick={this.toggleErrorModal}>
              Ok
            </AButton>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

export default EmailCapture
