import { connect } from 'react-redux'
import { ApplicationState } from 'store'

import InvoiceLayout from './InvoiceLayout'
import { getInvoice, InvoiceThunkDispatch, setInvoiceData, removeInvoiceData } from 'store/invoice'
import { DispatchProps } from './types'

export const mapState = (state: ApplicationState) => {
  return {
    invoice: getInvoice(state),
    user: state.user
  }
}

export const mapDispatch = (dispatch: InvoiceThunkDispatch): DispatchProps => ({
  setInvoiceData: invoice => dispatch(setInvoiceData(invoice)),
  removeInvoiceData: () => dispatch(removeInvoiceData())
})

export default connect(mapState, mapDispatch)(InvoiceLayout)
