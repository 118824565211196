import { ApiService } from 'core/services'
import { SendOneEMail, SendToContactBody } from './types'

class MailService {
  public static instance: MailService

  constructor() {
    if (MailService.instance) {
      return MailService.instance
    }
    MailService.instance = this
  }

  sendToContact(body: SendToContactBody) {
    return ApiService.post<any, SendToContactBody>('mail/sendToContact', body)
  }

  sendEmail(body: SendOneEMail) {
    return ApiService.post<any, SendToContactBody>('mail/sendOne', body)
  }
}

const instance = new MailService()

Object.freeze(instance)

export const Mail = instance
