import { ServiceDetail, InvoiceBody, BillingInfo, AddressInfo, BodyIngreso, TaxRegimen, UsoCFDI } from './types'
import { BillingData } from 'modules/invoice/models'
import { ApiService } from 'core/services'

class InvoiceService {
  public static instance: InvoiceService

  constructor() {
    if (InvoiceService.instance) {
      return InvoiceService.instance
    }
    InvoiceService.instance = this
  }

  getServiceDetail(code: string, type: string) {
    return ApiService.get<ServiceDetail>(`invoices/serviceDetail/${type}/${code}`).then(res => Promise.resolve({ ...res, code }))
  }

  getBillingData(rfc: string, zipCode: string) {
    return ApiService.get<BillingData[]>(`customers/billingInformation/${rfc}/${zipCode}`)
  }

  getBillingDataList(id: number) {
    return ApiService.get<BillingData[]>(`customers/getAllBillingInformation/${id}`)
  }

  requestInvoice(data: InvoiceBody) {
    return ApiService.post<any, InvoiceBody>('invoices/timboxSite', data)
  }

  registerTimbox(data: BodyIngreso) {
    return ApiService.post<any, BodyIngreso>('invoices/timboxRegisterSite', data)
  }

  createBillingInfo(data: BillingInfo) {
    return ApiService.post<any, BillingInfo>('customers/billingInformation', data)
  }

  searchByZipCode(zipCode: string): Promise<AddressInfo | null> {
    return ApiService.get<any[]>(`suburbs/zipCodeSearch/${zipCode}`).then(res => {
      if (res.length) {
        return Promise.resolve({
          countries: this.getDistinctList(res, 'country'),
          states: this.getDistinctList(res, 'state'),
          counties: this.getDistinctList(res, 'county'),
          cities: this.getDistinctList(res, 'city'),
          suburbs: this.getDistinctList(res, 'suburb')
        })
      }
      return Promise.resolve(null)
    })
  }

  getTaxRegimens() {
    return ApiService.get<TaxRegimen[]>(`customersBillingInfo/getRegimenFiscal`)
  }

  getUsosCFDI() {
    return ApiService.get<UsoCFDI[]>(`customersBillingInfo/getUsoCFDI`)
  }

  getDistinctList(list: any, key: string) {
    const obj = list.reduce((acu: any, a: any) => {
      const kId = `${key}_id`
      const kName = `${key}_name`
      const uKey = a[kId]
      if (!acu[uKey]) {
        acu[uKey] = {
          id: uKey,
          name: a[kName]
        }
      }
      return acu
    }, {})
    return Object.keys(obj).map(k => obj[k])
  }
}

const instance = new InvoiceService()

Object.freeze(instance)

export default instance
